
import { Options, Vue } from "vue-class-component";
import Sidebar from "@/app/ui/components/sidebar/index.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import Loading from "@/app/ui/components/loading/index.vue";
import Banner from "@/app/ui/views/banner/index.vue";
import FloatingBooking from "@/app/ui/components/floating-booking/index.vue";
import MultipleSnackbar from "@/app/ui/components/multiple-snackbar/index.vue";
import { FirebaseController } from "../controllers/FirebaseController";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import replaceDoubleBraces from "@/app/infrastructures/misc/common-library/ReplaceDoubleBraches";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import { CargoController } from "../controllers/CargoController";
import { AccountController } from "../controllers/AccountController";
import { BalanceMinusPenaltyController } from "@/app/ui/controllers/BalanceMinusPenaltyController";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import { NotificationsAll } from "@/domain/entities/BalanceMinusPenalty";
import router from "../router";

@Options({
  components: {
    Loading,
    sidebar: Sidebar,
    Banner,
    FloatingBooking,
    MultipleSnackbar
  },
  computed: {
    getCurrentRoute() {
      return this.$route;
    }
  }
})
export default class Layout extends Vue {
  created() {
    // handle scanner press CTRL+Shift+J
    document.addEventListener.call(
      window,
      "keydown",
      (event: any) =>
        event.ctrlKey &&
        event.shiftKey &&
        event.keyCode == 74 && // J
        event.preventDefault()
    );
  }
  mounted() {
    // toast realtime notification
    FirebaseController.onMessageFirebase((payload: any) => {
      const checkStatus = payload.data.status === "failed";
      if (checkStatus) {
        playNotification("failed_cargo");
      } else {
        playNotification("success_cargo");
      }
      if (
        AccountController.accountData.account_type_detail.type === "console" ||
        AccountController.accountData.account_type_detail.type === "sub-console"
      ) {
        CargoController.updateLastReadCargo();
      }
      
      this.$toast.add({
        severity: payload.data.status === "success" ? "success" : "error",
        detail: `${this.messageFirebaseNotification(payload)}`,
        life: 10000,
      });
    });

    this.getBalanceMinus();
    this.defineMultipleSnackBar();
  }

  messageFirebaseNotification(payload: any) {
    const successBooking = `${payload.data.destination_city_code} - ${payload.data.destination_city_name}`;
    const failedBooking = payload.data.reason_failed;
    const isRTCNotif = payload.data.title === "RTC Notification";
    const notifRTC = payload.notification?.body.split(",");
    const cancelBooking = payload.data.message.includes("cancel booking");


    if (isRTCNotif) {
      return `<b>${notifRTC[0]}</b> ${notifRTC[1]}`;
    } else if (cancelBooking) { 
      return payload.data.status === "success"
        ? `<b>No. Kargo ${payload.data.cargo_no}</b> Berhasil dibatalkan`
        : `<b>No. Kargo ${payload.data.cargo_no}</b> -  Gagal dibatalkan karena <b>${failedBooking}</b>.`;
    } else {
      return payload.data.status === "success"
        ? `<b>No. Kargo ${payload.data.cargo_no}</b> Berhasil Booking kargo ke <b>${successBooking}</b>`
        : `<b>No. Kargo ${payload.data.cargo_no}</b> Gagal Booking karena <b>${failedBooking}</b>`;
    }
  }
  
  // error modal
  get responseModal() {
    return MainAppController.responseModal;
  }
  get errorModal() {
    return MainAppController.errorMessageModal;
  }
  get errorEntities() {
    return MainAppController.errorMessageEntities;
  }
  get errorTextCancel() {
    return this.errorEntities.onTryAgain ? this.$t('modalButtonBackTitle') : "";
  }
  get errorTextSuccess() {
    return this.errorEntities.onTryAgain
      ? this.$t('modalButtonTryAgainTitle')
      : this.errorEntities.buttonSuccessText;
  }
  get errorOnSubmit() {
    return this.errorEntities.onTryAgain
      ? this.errorEntities.onTryAgain
      : this.closeErrorModal;
  }
  closeErrorModal() {
    this.errorEntities.onClose && this.errorEntities.onClose();
    MainAppController.closeErrorMessage();
  }
  setCloseResponseModal() {
    MainAppController.closeResponseModal();
  }
  setConfirmResponseModal() {
    this.setCloseResponseModal();
    this.errorEntities.onClose();
  }

  // modal message
  get messageModal() {
    return MainAppController.messageModal;
  }
  get messageEntities() {
    return MainAppController.messageEntities;
  }

  // loading
  get loadingModal() {
    return MainAppController.loadingModal;
  }
  set loadingModal(bool: boolean) {
    MainAppController.setLoadingModal(bool);
  }

  // snackbar
  get snackbar() {
    return MainAppController.snackbar;
  }
  get snackbarMessage() {
    return MainAppController.snackbarMessage;
  }
  get snackbarPositive() {
    return MainAppController.snackbarPositive;
  }
  get snackbarTimeout() {
    return MainAppController.snackbarTimeout;
  }
  get snackbarAutoClose() {
    return MainAppController.snackbarAutoClose;
  }
  toggleSnackbar(bool: boolean) {
    MainAppController.setSnackbar(bool);
  }

  openCollapseSidebar() {
    AccountController.setIsCollapseSidebar(
      !AccountController.isCollapseSidebar
    );
  }
  toDashboard() {
    router.push("/");
  }

  async getBalanceMinus() {
    if (AccountController.accountData.isPosAccount 
      && AccountController.accountData.account_type_detail.isCodDelivery
    ) {
      const res = await BalanceMinusPenaltyController.getAllNotifications();
      if (this.isShowBalanceNotification) {
        res.forEach((item: NotificationsAll) => {
          const status = item.status === "info" ? "warning" : "error"
          const message = item.message;
          const dueDate: any = {
            "np_due_date": formatDate(item.dueDate)
          }
          
          const newMessage = replaceDoubleBraces(message, dueDate)
          this.$toast.add({
            severity: status,
            detail: newMessage
          });
        });
      }
    }
  }

  detailMultipleSnackBar = {
    title: "",
    isClickable: false,
  }

  defineMultipleSnackBar() {
    const account = AccountController.accountData.account_type_detail.type;
    const detailAccount: any = {
      "console": {
        title: "Notifikasi Kargo",
        isClickable: true,
      },
      "pos": {
        title: "Notifikasi Topup Saldo",
        isClickable: false,
      },
    }

    this.detailMultipleSnackBar.title = detailAccount[account]?.title ?? "";
    this.detailMultipleSnackBar.isClickable = detailAccount[account]?.isClickable ?? false;
  }

  get isShowBalanceNotification() {
    return (
      AccountController.accountData.isPosAccount &&
      BalanceController.balanceData.walletBalance <= 0
    );
  }

  get isShowCargoNotification() {
    return AccountController.accountData.isConsoleAccount || AccountController.accountData.isSubConsoleAccount;
  }

  get isAccountForeign() {
    return AccountController.accountData.accountIsForeign;
  }
}
